import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cx } from "#app/utils/cva.ts";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
	React.ElementRef<typeof TooltipPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 8, ...props }, ref) => (
	<TooltipPrimitive.Content
		className={cx(
			"fade-in-0 zoom-in-95 data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 animate-in overflow-hidden rounded-md bg-white px-3 py-1.5 text-gray-700 text-xs shadow-gray-200/50 shadow-lg ring-1 ring-gray-200/75 data-[state=closed]:animate-out dark:bg-[#202035] dark:text-zinc-300 dark:shadow-black/50 dark:ring-zinc-700/75",
			className,
		)}
		ref={ref}
		sideOffset={sideOffset}
		{...props}
	/>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };
